import React, { Component } from 'react';
import Display from "../components/display";

export default class Home extends Component {


  render() {
    return (
      <Display search={{ "type": "search" }} />
    )
  }
}
