import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "./display.css";
import Ad from "./ad.js";
import axios from 'axios';
import logopalc from "../files/logopalc.png";
import "./display.css";
import PersoPalc from "./../files/perso_palc.png";
import AucuneAnnonce from "./../files/AucuneAnnonce.png";
import AucunResultat from "../files/AucunResultat.png";
import $ from 'jquery';
import Cookies from 'universal-cookie';
import FilterSearch from "./filter_search";

let cookies = new Cookies();
window.$ = $;

class Display extends Component {
  //props : search = dictionary defining the appropriate search for the db (cf different pages in views/)
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      adsDisplayed: 16,
      numberAds: 0,
      data: [],
      filter: JSON.parse(JSON.stringify(this.props.search))
    }
    this.updateDisplay = this.updateDisplay.bind(this);
  }

  componentDidMount() {
    this.setState({ data: this.searchDataFromDb(this.state.filter, this.state.page, this.state.adsDisplayed) });
    this.setState({ searchedfor: this.state.filter})
    this.getNumberAds(this.state.filter);
  }

  changePage = (i) => { //change page to page number i
    this.setState({ page: i });
    this.searchDataFromDb(this.state.filter, this.state.page, this.state.adsDisplayed);
  }

  updateDisplay(key, value) { //filter the data according to what was chosen in the child form
    this.setState({ page: 0 }); // we come back to the front page of the pagination

    if (value.length === 0) {
      key === "tag" ? delete this.state.filter.tag : delete this.state.filter.location;
    }

    else { 
      this.setState(({filter}) => ({ filter: [...filter.slice(0,key), value, ...filter.slice(key+1)] }));
    }

    this.setState({ data: this.searchDataFromDb(this.state.filter, this.state.page, this.state.adsDisplayed) });
    this.getNumberAds(this.state.filter);
  }

  previousNext = (i) => { // change page to the next or previous
    this.setState({ page: this.state.page + i })
    if (this.state.page < 0) {
      this.setState({ page: 0 })
    }
    if (this.state.page > (this.state.numberAds - 1) / this.state.adsDisplayed) {
      this.setState({ page: Math.floor((this.state.numberAds - 1) / this.state.adsDisplayed) });
    }
    this.searchDataFromDb(this.state.filter, this.state.page, this.state.adsDisplayed);
  }

  getNumberAds = (search) => {
    axios.post("/api/getNumberAds", { search: search }, { headers: { authorization: `Bearer ${cookies.get("auth")}`, login: cookies.get("login") } })
      .then(data => data.data).then(res => { this.setState({ numberAds: res.data }) });

  }
  searchDataFromDb = (search, page, numberAdsToGet) => { // 
    axios.post("/api/searchData", { search: search, numberAdsToGet: numberAdsToGet, page: page }, { headers: { authorization: `Bearer ${cookies.get("auth")}`, login: cookies.get("login") } })
      .then(data => data.data).then(res => { this.setState({ data: res.data }) });
  };

  createPagination = (numberToDisplay = 1) => {
    let pagination = [];
    for (let i = -numberToDisplay; i <= numberToDisplay; i++) {
      pagination.push(i + this.state.page);
    }
    pagination = pagination.filter((elem) => elem > -1).filter((elem) => elem < Math.ceil(this.state.numberAds / this.state.adsDisplayed));
    if (pagination[0] > 1) {
      pagination = [0, -1].concat(pagination);
    }
    else if (pagination[0] > 0) {
      pagination = [0].concat(pagination);
    }
    if (pagination[pagination.length - 1] < Math.ceil(this.state.numberAds / this.state.adsDisplayed) - 2) {
      pagination = pagination.concat([-1, Math.ceil(this.state.numberAds / this.state.adsDisplayed - 1)])
    }
    else if (pagination[pagination.length - 1] < Math.ceil(this.state.numberAds / this.state.adsDisplayed) - 1) {
      pagination = pagination.concat([Math.ceil(this.state.numberAds / this.state.adsDisplayed - 1)])
    }


    return pagination;
  }

  render() {
    let pagination = this.createPagination(0);
    if (this.state.filter != this.state.searchedfor) {
      this.setState({ data: this.searchDataFromDb(this.state.filter, this.state.page, this.state.adsDisplayed) });
      this.setState({ searchedfor: this.state.filter });
    }
    if (this.state.data) {
      if (this.state.data.length === 0) {
        if (this.props.location.pathname === "/perso") {
          return (<section>
            <div className="container-fondus ">
              <div className="row">
                <div className="col-lg-6 mx-auto text-center">

                  <br></br>
                  <img src={PersoPalc} id="aucuneannonce_image" className="img-fluid" alt="Responsive image" />
                </div>
              </div>
            </div>
          </section>)
        }
        else if (this.props.match.path === "/searchEngine/:searchText") {
          return (<section>
            <div className="container-fondus ">
              <div className="row">
                <div className="col-lg-6 mx-auto text-center">

                  <br></br>
                  <img src={AucunResultat} id="aucuneannonce_image" className="img-fluid" alt="Responsive image" />
                </div>
              </div>
            </div>
          </section>)
        }
        else {
          return (<section>
            <FilterSearch updateDisplay={this.updateDisplay} tag={this.state.filter["tag"]} loc={this.state.filter["location"]} />
            <div className="container-fondus ">
              <div className="row">
                <div className="col-lg-6 mx-auto text-center">

                  <br></br>
                  <img src={AucuneAnnonce} id="aucuneannonce_image" className="img-fluid" alt="Responsive image" />
                </div>
              </div>
            </div>
          </section>)
        }
      }
      else {
        return (
          <div>
            <FilterSearch updateDisplay={this.updateDisplay} tag={this.state.filter["tag"]} loc={this.state.filter["location"]} />
            <div className="row justify-content-center">
              <div className="card-deck">
                {this.state.data.map(dat => (<Ad key={dat._id} data={dat} />))}
              </div>
            </div>
            <nav className="row justify-content-center" aria-label="Page navigation example">
              <ul className="pagination">
                <li className="page-item"><a className="page-link" onClick={() => this.previousNext(-1)}>Prev</a></li>
                {pagination.map(i => (<li className={"page-item" + (this.state.page == i ? " active" : " ")} key={i}>{i == -1
                  ? <button id="between-pages" type="button" className="page-link"  >...</button>
                  : <a key={i} className="page-link" onClick={() => this.changePage(i)}>{i + 1}</a>}</li>))}
                <li className="page-item"><a className="page-link" onClick={() => this.previousNext(1)}>Next</a></li>
              </ul>
            </nav>
          </div>
        )
      }
    }
    else {
      return (
        <div className="app-loading">
          <img id="icon_spinner" src={logopalc} />
          <svg className="spinner" viewBox="25 25 50 50">
            <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10" />
          </svg>
        </div>
      )
    }
  }
}


export default withRouter(Display);