import { withRouter } from 'react-router-dom';
import "./filter_search.css";
import React, { Component } from 'react';

const tag_options = [
    ["Toutes catégories", ""],
    ["Clé/carte de chambre", "key"],
    ["Portefeuille, cartes", "wallet"],
    ["Téléphone", "phone"],
    ["Vêtement et autres textiles", "cloth"],
    ["Chargeur", "charger"],
    ["Lunchbox et ecocup", "lunchbox"],
    ["Lunettes", "glasses"],
    ["Casque et écouteurs", "headset"],
    ["Gourdes et thermos", "bottle"],
    ["Montre et bijoux", "watch"],
    ["Autre", "misc"]] // tags to select from
const location_options = [
    ["Toutes localisations", ""],
    ["Résidence 1", "rez1"],
    ["Résidence 2", "rez2"],
    ["Résidence 3", "rez3"],
    ["Résidence 4", "rez4"],
    ["Résidence 5", "rez5"],
    ["Résidence 6", "rez6"],
    ["Bâtiment Eiffel", "eiffel"],
    ["Bâtiment Bouygues", "bouygues"],
    ["Bâtiment Bréguet", "breguet"],
    ["Soirée", "soiree"],
    ["Autre", "misc"]] // locations to select from
class FilterSearch extends Component {

    handleChange=(event) => {
        this.props.updateDisplay((event.target.name === "category-selection" ? "tag" : "location"), event.target.value);
    }
    
    render() {


        const tags = []
        for (var i = 0; i < tag_options.length; i++) {
         tags.push(<option selected={this.props.tag === tag_options[i][1]} value={tag_options[i][1]}> {tag_options[i][0]} </option>)
        }

        const locations = []
        for (i = 0; i < location_options.length; i++) {
        locations.push(<option selected={this.props.loc === location_options[i][1]} value={location_options[i][1]}> {location_options[i][0]} </option>)
        }

        return (<div id="filter-bar">
            <label htmlFor="category-select" id="category-tag">Choisissez une catégorie:</label>
            <label htmlFor="location-select-location" id="location-tag">Choisissez un lieu:</label>
            <select onChange={this.handleChange} name="category-selection" id="category-select">
                {tags}
            </select>
            <select onChange={this.handleChange} name="location-selection" id="location-select">
                {locations}
            </select>
        </div>)
    }
}


export default withRouter(FilterSearch);
export { tag_options, location_options };