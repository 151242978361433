import React, { Component } from 'react';
import "./home.css";
import logopalccouleur from "../files/logopalccouleur.png";

export default class All extends Component {

  render() {
    return (
      <section>
        <div className="container-fondus ">
          <div className="row">
            <div className="col-lg-6 mx-auto text-center">

              <br></br>
              <h1 className="section-heading"> Bienvenue sur PALC ! </h1>
              <hr className="light my-4" />
              <img src={logopalccouleur} id="image_welcome_page" className="img-fluid" alt="Responsive image" />
              <p> Mais qu&apos;est ce que PALC peut bien vouloir dire ?  Ca veut dire &quot;Pinte à la clé&quot; ! Tu peux trouver ici les objets trouvés,
                ou faire une annonce si tu as trouvé un objet. Les objets peuvent être classés par catégorie.<br />
                Si vous rencontrez un problème ou avez une remarque, n&apos;hésitez pas à envoyer un mail à
                <a id="mail_contact" href="mailto:palc@ml.viarezo.fr"> palc@ml.viarezo.fr</a>
                <br /><br />
                Certains objets trouvés par le personnel de l&apos;école ou d&apos;autres personnes en Eiffel ou Bouygues sont centralisés
                pendant 2-3 jours à l&apos;accueil d&apos;Eiffel (dans l&apos;atrium au fond de la diagonale), puis déplacés à l&apos;acceuil de Bréguet et gardés assez longtemps !<br /><br />
                N&apos;oubliez pas les bonnes pratiques quand vous créez des annonces: flouter les numéros de cartes bleues
                ou autres informations sensibles, supprimer une annonce si l&apos;objet a été trouvé et rendu à son propriétaire...
                </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
