import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import axios from 'axios';
import "./ad.css";
import Cookies from 'universal-cookie';
import image from "../files/defaultphoto.png";
import { location_options, tag_options } from './filter_search';

let cookies = new Cookies(); //initialize cookies

function convertValueToName(object,list) {
  for (var i = 0; i < list.length; i++) {
    if (list[i][1] === object) {
      return (list[i][0])
    }
  }
  return "Non renseigné"
}


export class Page extends Component { // full page view of the ad with the id written in the URI


  state = {
    data: null,
  }

  componentDidMount() {
    this.searchDataFromDb(); // get ads to display
  }

  handleDelete = () => { // delete the ad

    if (window.confirm("Voulez vous vraiment supprimer cette annonce ?")) {
      axios.post("/api/deleteData", { id: this.props.match.params.id, auth: cookies.get("auth") }, { headers: { authorization: `Bearer ${cookies.get("auth")}`, login: cookies.get("login") } }); // auth to check whether it is the true author or not (or an admin)
      this.props.history.push("/perso");
    }

  }

  handleUpdate = () => { // redirect to the page to update the ad

    this.props.history.push("/updatePost/" + this.props.match.params.id);

  }

  searchDataFromDb = () => { // gather data from the DB

    axios.post("/api/searchById", { id: this.props.match.params.id, auth: cookies.get("auth") }, { headers: { authorization: `Bearer ${cookies.get("auth")}`, login: cookies.get("login") } })
      .then(data => { return (data.data) })
      .then(res => { this.setState({ show_button: res.show_button }); this.setState({ data: res.data }) }); // data : content of ads, show_button : whether to show update and delete buttons
  };


  render() {
    let buttonDelete = null;
    let buttonUpdate = null;



    if (this.state.data && this.state.show_button) { // if we have some data and have to show the button
      buttonDelete = <button className="btn btn-danger col" onClick={this.handleDelete} style={{ "border-radius": "50px", "padding": "10px" }}><i className="fa fa-trash" style={{ "color": "white", "margin-right": "4px" }}></i>Delete</button>;
      buttonUpdate = <button className="btn btn-primary col" style={{ "marginRight": "1rem", "marginLeft": "1rem", "border-radius": "50px", "padding": "10px" }} onClick={this.handleUpdate}><i className='fa fa-edit' style={{ "color": "white", "margin-right": "4px" }}></i>Edit</button>;

    }

    if (this.state.data) { //if we have data

      let carousel = null;

      if (this.state.data.image[0] === "" | this.state.data.image[0] === null) {
        this.setState({
          data: { image: [image]}
        })
      }

      if ((this.state.data.image[0] != null & this.state.data.image[0] != "") | this.state.data.image.length) { //if there is a image[0] or images to show create the carousel
        carousel = <div id="carouselExampleControls" className="carousel slide col" data-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div><div className="row justify-content-center" style={{ "height": "300px" }}><img className="img-fluid img-test" src={this.state.data.image[0]} alt="image[0]" /></div></div>

            </div>
            {this.state.data.image.slice(1).map((img) => <div key={img.slice(img.length - 20, img.length - 1)} className="carousel-item">
              <div><div className="row justify-content-center" style={{ "height": "300px" }}><img className="img-fluid img-test" src={img} alt="images" /></div></div>
            </div>)}
          </div>
          <a className="carousel-control-prev " style={{ "color": "red" }} href="#carouselExampleControls" role="button" data-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>;
      }

      let presentation = "Trouvaille : Cette annonce a été créée par " + this.state.data.author;
      if (this.state.data.reward !== null && this.state.data.reward !== "null") {
        presentation = "Recherche : " + this.state.data.author + " offre " + this.state.data.reward.toLowerCase();
      }
      let affiliation_tag = "🔎 Catégorie : " + convertValueToName(this.state.data.tag,tag_options);
      let affiliation_location = "🏠 Localisation : " + convertValueToName(this.state.data.location,location_options);
      let date = "Annonce publiée le " + this.state.data.createdAt.slice(8, 10) + "/" + this.state.data.createdAt.slice(5, 7) + "/" + this.state.data.createdAt.slice(0, 4);
      return (
        <div className="col-lg-8">
          <div className='box-add'>
            <div>
              <h1 className="text-center" id="titre_cancer">{this.state.data.title}</h1>
            </div>
            <div className='underline-long' />
            <br />
            <div className="row align-items-center justify-content-center">
              {carousel}
              <div className="col-sm-6 text-center ">
                <h5 className="type-add">{presentation}</h5>
                <p className="cat-ad">{affiliation_tag} <br /> {affiliation_location}</p>
                <p className="date-ad" style={{ "font-family": "roboto-light", "fontSize": "0.9em", "opacity": "1" }}>{date}</p>
                <p className="card-text text-center" id="description">{this.state.data.description}</p>
                <a className="text-center" href={"https://linkcs.fr/user/" + this.state.data.author_login}><button className="btn btn-info col" style={{ "border-radius": "50px", "padding": "10px" }}  ><i className='fa fa-user' style={{ "color": "white", "margin-right": "2px" }}></i>LinkCS </button></a>
                {buttonUpdate}
                {buttonDelete}
              </div>
            </div>
          </div>
        </div>)
    }
    else { return (<div />) }
  }
}


class Ad extends Component { // card for the mutliple ads view
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      value: null,

    }

    this.handleChange = this.handleChange.bind(this);
  }

  printTitle = () => { //cut the title if too long

    if (this.props.data.title && this.props.data.title.length > 120) {
      return (this.props.data.title.slice(0, 100) + " ...");
    }
    else {
      return (this.props.data.title);
    }

  }

  printDescription = () => { // cut the description if too long

    if (this.props.data.description && this.props.data.description.length > 200) {
      return (this.props.data.description.slice(0, 150) + " ...");
    }
    else {
      return (this.props.data.description);
    }

  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    this.props.updateParent("title", event.target.value);
  }


  render() {
    let date = new Date(this.props.data.createdAt);
    let className = "card h-100"//+ (this.props.data.type === "search" ? " bg-secondary" : " bg-success");
    if (this.props.data.image == undefined) {
      this.props.data.image = [""];
    }
    if (this.props.data.image[0] === "" | this.props.data.image[0] === null | this.props.data.image[0] === Array[0]) {
      this.props.data.image[0] = image
    }
    return (
      <a className="style-1" href={"/ad/" + this.props.data._id} style={{ "marginTop": "1rem", "marginLeft": "auto", "marginRight": "auto", "marginBottom": "1rem" }}>
        <div className={className} style={{ "width": "18rem" }} >
          <div>
            <h5 className="card-title text-justify" style={{ "marginTop": "10px" }}> &nbsp; {(this.props.data.type !== this.props.location.pathname.slice(1)) ? this.props.data.type === "search" ? "Recherche" : "Trouvaille" : ""} &nbsp; <span style={{ "float": "right", "paddingTop": ".175rem", "fontSize": "1rem" }}>{date.toLocaleDateString("fr-FR")} &nbsp;</span> </h5>
            <p className="card-affiliation">🔎 {convertValueToName(this.props.data.tag,tag_options)} <br /> 🏠 {convertValueToName(this.props.data.location,location_options)} </p>
          </div>
          <LinkContainer to={"/ad/" + this.props.data._id}>
            <img className="card-img-top test" style={{ "max-height": "1px" }} alt={this.props.data._id} src={this.props.data.image[0]} />
          </LinkContainer>
          <LinkContainer to={"/ad/" + this.props.data._id}>
            <div className="card-body ">
              <h5 className="card-title">{this.printTitle()}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{this.props.data.author + (this.props.data.type === "search" ? " offre " + this.props.data.reward.toLowerCase() : "")}</h6>
              <p className="card-text">{this.printDescription()}</p>
            </div>
          </LinkContainer>
        </div>
      </a>)
  }
}

export default withRouter(Ad)